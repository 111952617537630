.card-box {
  overflow: hidden;
  cursor: pointer;
  max-height: calc(20% - 200px); }
  .card-box .card-name {
    font-size: 25px;
    color: white;
    text-align: center;
    letter-spacing: 7px;
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-weight: 300; }
  .card-box .project-img {
    width: calc(100% + 2px);
    height: 293px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
