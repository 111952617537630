body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

p {
  color: #616161; }

p::selection {
  background: #D1C7AB;
  color: #ffffff !important; }

#root {
  position: relative;
  min-height: stretch; }

#page {
  width: calc(100% - 332px);
  position: absolute;
  left: 250px;
  top: 60px;
  padding: 32px 41px 160px;
  background-color: #FFFFFF; }
  #page .page-title-icon {
    width: 16px;
    height: 16px;
    display: inline-block; }
  #page .page-title {
    color: #8D825F;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    display: inline-block;
    margin: 0;
    margin-left: 8px; }
    #page .page-title .page-title-span {
      color: #838382;
      font-weight: 400; }

textarea {
  resize: none; }
