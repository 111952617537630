#mobile-menu {
  width: 100%; }
  #mobile-menu .menu-box {
    position: relative;
    display: flex;
    z-index: 4;
    background-color: #fff;
    padding: 10px 0 20px 0; }
    #mobile-menu .menu-box .logo {
      height: 80px;
      display: flex;
      margin: 0px 0 0px 8px; }
    #mobile-menu .menu-box .icon {
      position: absolute;
      font-size: 30px;
      color: #8D825F;
      right: 32px;
      top: 40px; }
  #mobile-menu .invisible {
    transform: translateY(calc(-100% - 100px)); }
  #mobile-menu .menu-options-enter {
    transform: translateY(calc(-100% - 100px)); }
  #mobile-menu .menu-options-enter-active {
    transform: translateY(0%);
    transition: all 300ms ease-out; }
  #mobile-menu .menu-options-exit {
    transform: translateY(0%); }
  #mobile-menu .menu-options-exit-active {
    transform: translateY(calc(-100% - 100px));
    transition: all 300ms ease-in; }
  #mobile-menu .menu-slider {
    position: relative;
    background-color: #fff;
    width: 100%;
    z-index: 3; }
    #mobile-menu .menu-slider .menu-text {
      color: #616161;
      padding: 0px 0 32px 22px;
      margin: 0;
      background-color: #fff; }

#menu {
  position: fixed;
  width: 250px;
  height: 100%;
  padding: 10px 0 20px 0;
  background-color: #fff;
  z-index: 1;
  overflow: auto; }
  #menu .menu-wrapper {
    overflow: hidden;
    position: relative;
    height: inherit; }
  #menu .logo {
    height: 250px;
    display: block;
    margin: 8px 0 28px -60px; }
  #menu .title {
    font-size: 14px;
    margin: 0 0 16px 0;
    text-align: center; }
    #menu .title:hover {
      text-decoration: underline; }
    #menu .title.selected {
      color: #8D825F; }
  #menu .icon-row {
    display: flex;
    width: fit-content;
    margin: 0px auto 8px; }
    #menu .icon-row .icon {
      font-size: 28px;
      color: #8D825F;
      margin: 8px 16px; }
  #menu .small-text {
    font-size: 11px;
    text-align: center;
    margin: 0; }
    #menu .small-text.email {
      margin-bottom: 16px; }
  #menu .footer {
    width: 100%;
    position: absolute;
    bottom: 40px; }
