#contact-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%; }
  #contact-page .page-layout {
    position: absolute;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 2; }
    #contact-page .page-layout .title {
      text-align: center;
      margin: 40px 0 16px 0;
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
      width: 90%; }
    #contact-page .page-layout .input {
      margin-bottom: 32px; }
    #contact-page .page-layout .footer {
      position: absolute;
      bottom: 16px;
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #838382; }
  @media screen and (max-width: 480px) {
    #contact-page .page-layout {
      left: 24px;
      right: 0px;
      top: 80px;
      bottom: 0;
      overflow: auto;
      z-index: 2; }
      #contact-page .page-layout .title {
        width: 100%; }
      #contact-page .page-layout .input {
        margin-bottom: 48px; }
    #contact-page .button {
      width: 120px;
      height: 48px;
      font-size: 17px; } }

.alert-card-component {
  padding: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  border: 0;
  background-color: #ffffff; }
  .alert-card-component:focus {
    outline: none; }
  .alert-card-component .text {
    font-size: 13px; }

.button {
  width: 80px;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  margin-top: 8px;
  border: none;
  background-color: #8D825F;
  color: #fff; }
  .button:focus {
    outline: none; }
  .button.mobile {
    float: right;
    margin-right: 20px; }
