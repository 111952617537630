#about-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%; }
  #about-page .page-layout {
    display: flex;
    position: absolute;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2; }
    #about-page .page-layout .photo-column {
      width: 70%;
      position: relative;
      margin-bottom: 80px; }
      #about-page .page-layout .photo-column .picture {
        width: 100%;
        margin-top: 32px; }
      #about-page .page-layout .photo-column .description {
        margin: 0; }
        #about-page .page-layout .photo-column .description.margin {
          margin-bottom: 80px; }
    #about-page .page-layout .text-column {
      width: 30%;
      position: relative;
      padding: 16px;
      overflow: scroll; }
      #about-page .page-layout .text-column .title {
        text-align: center; }
      #about-page .page-layout .text-column .subtitle {
        text-align: center;
        font-size: 14px; }
      #about-page .page-layout .text-column .text {
        margin: 0;
        font-size: 15px;
        text-align: justify; }
        #about-page .page-layout .text-column .text.margin {
          margin-bottom: 16px; }
        #about-page .page-layout .text-column .text.right {
          text-align: right; }
        #about-page .page-layout .text-column .text span {
          display: inline-block;
          width: 20px; }
  @media screen and (max-width: 480px) {
    #about-page .page-layout {
      display: block;
      top: 80px;
      left: 0;
      overflow: auto; }
      #about-page .page-layout .photo-column {
        width: 100%;
        margin-bottom: 0; }
        #about-page .page-layout .photo-column .picture {
          width: 100%; }
        #about-page .page-layout .photo-column .description {
          margin: 0 0 0 32px; }
      #about-page .page-layout .text-column {
        width: calc(100% - 32px);
        padding: 16px 16px 80px 16px; } }
