#home-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%; }
  #home-page .hero {
    background-image: url("/home.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    position: absolute;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2; }
    #home-page .hero .text-box {
      position: absolute;
      left: 500px;
      right: 20px;
      top: 60px;
      font-size: 17px; }
      #home-page .hero .text-box .text {
        color: #ffffff;
        margin: 0; }
        #home-page .hero .text-box .text.margin {
          margin-bottom: 24px; }
  @media screen and (max-width: 480px) {
    #home-page .hero {
      background-size: cover;
      background-position-x: right;
      background-position-y: bottom;
      left: 0;
      top: 100px;
      opacity: .7;
      width: 100%;
      height: 100%; }
      #home-page .hero .text-box {
        top: 40px;
        width: 210px;
        text-align: justify;
        left: 20px; }
        #home-page .hero .text-box .text {
          font-size: 16px; }
          #home-page .hero .text-box .text.margin {
            margin-bottom: 16px; } }
