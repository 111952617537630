#projects-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%; }
  #projects-page .page-layout {
    position: absolute;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 2;
    position: flex; }
  @media screen and (max-width: 480px) {
    #projects-page .page-layout {
      left: 0;
      top: 80px;
      padding-bottom: 400px; } }
