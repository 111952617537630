#project-details-page {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%; }
  #project-details-page .page-layout {
    display: flex;
    position: absolute;
    left: 250px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2; }
    #project-details-page .page-layout .parent-column {
      display: flex; }
      #project-details-page .page-layout .parent-column .photo-column-fixed {
        width: 70%;
        position: relative;
        padding-bottom: 40px;
        overflow: auto; }
        #project-details-page .page-layout .parent-column .photo-column-fixed .picture {
          width: 100%; }
        #project-details-page .page-layout .parent-column .photo-column-fixed .description {
          margin: 0; }
      #project-details-page .page-layout .parent-column .text-column {
        width: 30%;
        position: relative;
        padding: 16px; }
        #project-details-page .page-layout .parent-column .text-column .title {
          text-align: center; }
        #project-details-page .page-layout .parent-column .text-column .attribute-box {
          display: flex;
          margin-bottom: 6px; }
          #project-details-page .page-layout .parent-column .text-column .attribute-box .icon {
            width: 22px;
            height: 22px;
            margin-right: 8px; }
          #project-details-page .page-layout .parent-column .text-column .attribute-box .attribute-text {
            margin: 0;
            font-size: 14px; }
          #project-details-page .page-layout .parent-column .text-column .attribute-box:first-child {
            margin-top: 20px; }
          #project-details-page .page-layout .parent-column .text-column .attribute-box:last-child {
            margin-bottom: 20px; }
        #project-details-page .page-layout .parent-column .text-column .text {
          margin: 0;
          font-size: 14px;
          text-align: justify; }
          #project-details-page .page-layout .parent-column .text-column .text.margin {
            margin-bottom: 16px; }
        #project-details-page .page-layout .parent-column .text-column .logos-wrapper .logo {
          height: 50px;
          margin: 0 8px; }
  @media screen and (max-width: 480px) {
    #project-details-page .page-layout {
      display: block;
      left: 0;
      top: 80px; }
      #project-details-page .page-layout .parent-column .photo-column-fixed {
        width: 100%;
        position: absolute;
        padding-bottom: 40px;
        overflow: hidden; }
        #project-details-page .page-layout .parent-column .photo-column-fixed .description {
          margin: 0; }
        #project-details-page .page-layout .parent-column .photo-column-fixed .card-row {
          margin-top: 24px;
          margin-bottom: 24px;
          position: relative;
          display: flex;
          width: fit-content;
          background-color: rgba(0, 0, 0, 0.8); }
          #project-details-page .page-layout .parent-column .photo-column-fixed .card-row .picture-box {
            position: relative;
            display: block;
            height: auto;
            width: 400px; }
            #project-details-page .page-layout .parent-column .photo-column-fixed .card-row .picture-box .picture {
              height: 260px;
              width: auto;
              display: block;
              margin: auto;
              max-width: 100%; }
        #project-details-page .page-layout .parent-column .photo-column-fixed .swipe-box {
          position: absolute;
          width: 38px;
          height: 38px;
          top: 140px;
          padding: 0px !important;
          z-index: 99; }
          #project-details-page .page-layout .parent-column .photo-column-fixed .swipe-box.right {
            right: 16px; }
          #project-details-page .page-layout .parent-column .photo-column-fixed .swipe-box.left {
            left: 16px; }
          #project-details-page .page-layout .parent-column .photo-column-fixed .swipe-box .swipe {
            font-size: 40px;
            color: white;
            margin: -1px; }
      #project-details-page .page-layout .parent-column .text-column {
        width: calc(100% - 32px);
        padding: 16px 16px 80px 16px;
        position: absolute;
        top: 280px;
        left: 0;
        right: 0;
        bottom: 0; } }
